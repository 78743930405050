.testimonialSection {
    background: #F5FFFA;
}

.testmonialsCard {
    /* padding: 5px; */
    border: none !important;
    width: 90% !important;
    margin-left: 5%;
}

.testimonialImage {
    border-radius: 500px !important;
    height: 70px !important;
    width: 70px;
}

.testimonialLocation {
    margin-top: -10px;
}

.testimonialRatings {
    display: flex;
}
.testimonialRatings1{
    width: 20px;
    height: 20px;
}

.testimonialDescription {
    margin: 0;
    padding: 5px;
    color: black;
    position: relative;
    transition: background-color 0.6s linear;
    border-radius: 10px;

}

/* .testimonialDescription:after {
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    bottom: 100%;
    border: solid transparent;
    border-bottom-color: #e4510b;
    border-left-color: #e4510b;
    border-width: 10px;
    left: 10%;
} */



.footer-Services {
    color: #e4510b;
    top: 0;
    animation: slide 5s infinite;
}