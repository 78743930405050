.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rdt_TableHeadRow {
  background: aliceblue !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.rdt_TableRow {
  min-height: 80px !important;
}

.bYJJDf {
  flex-grow: 0.5 !important;
}

.whatsapp-sticky {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 65px;
  z-index: 50;
}


@media (max-width: 1300px) {
  .whatsapp-sticky {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 55px;
    z-index: 50;
  }
}

@media (max-width: 768px) {
  .whatsapp-sticky {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    z-index: 50;
  }
}

@media (max-width: 568px) {
  .whatsapp-sticky {
    position: fixed;
    bottom: 25px;
    right: 20px;
    width: 55px;
    z-index: 50;
  }
}