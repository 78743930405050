.viewButton {
    height: 50px;
    width: 130px;
    background-color: white;
    border: 1px solid #E4510B;
    color: #E4510B;
    border-radius: 4px;
    font-weight: bolder;
}

.product-img {
    width: 100%;
    display: block;
}

.product-imgs .img-display {
    overflow: hidden;
}

.slick-arrow {
    display: none !important;
}

.slick-dots {
    color: #FBD7C6;
}

.accordion-button::after {
    background-image: url("../../assets//images/banner/plus.png") !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../../assets/images/banner/minus.png") !important;
}

.slick-dots li.slick-active button:before {
    color: #E4510B;
}

.slick-dots li button:before {
    color: #d6a893;
    size: 20px;
}

.slick-dots {
    margin-top: -80px;
}

.product-imgs .img-showcase {
    display: flex;
    width: 100%;
    transition: all 0.5s ease;
}

.product-imgs .img-showcase img {
    min-width: 100%;
}

.product-imgs .img-select {
    display: flex;
}

.product-imgs .img-item {
    margin: 0.3rem;
}

.product-imgs .img-item:nth-child(1),
.product-imgs .img-item:nth-child(2),
.product-imgs .img-item:nth-child(3) {
    margin-right: 0;
}

.product-imgs .img-item:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {

    .prev-button {
        display: none !important;
    }

    .next-button {
        display: none !important;
    }
}

@media screen and (min-width: 992px) {


    .product-imgs {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}


@media (max-width: 568px) {
    .price-head {
        font-size: 18px !important;
    }

    .prev-button {
        display: none !important;
    }

    .next-button {
        display: none !important;
    }

    .viewButton {
        height: 50px;
        width: 130px;
        background-color: white;
        border: 1px solid #E4510B;
        border-radius: 8px;
        color: #E4510B;
        font-weight: bolder;
    }

    .des {
        padding-bottom: 15% !important;
    }

    .heartEmoji {
        display: flex;
        justify-content: flex-end;
    }

    .heart-like {
        margin-top: 10px;
    }
}

.con-like {
    --red: rgb(255, 50, 50);
    position: relative;
    width: 35px;
    height: 35px;
    /* border: 1px solid #C8C7C7; */
    margin-left: 7px;
    margin-top: 6px;
}

.con-like .like {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
}

.con-like .checkmark {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.con-like .outline,
.con-like .filled {
    fill: var(--red);
    position: absolute;
}

.con-like .filled {
    animation: kfr-filled 0.5s;
    display: none;
}

.con-like .celebrate {
    position: absolute;
    animation: kfr-celebrate 0.5s;
    animation-fill-mode: forwards;
    display: none;
}

.con-like .poly {
    stroke: var(--red);
    fill: var(--red);
}

.con-like .like:checked~.checkmark .filled {
    display: block
}

.con-like .like:checked~.checkmark .celebrate {
    display: block
}

.con-like1 {
    --red: rgb(255, 50, 50);
    position: relative;
    width: 35px;
    height: 35px;
    /* border: 1px solid #C8C7C7; */
    margin-left: 7px;
    margin-top: 6px;
}

.con-like1 .like1 {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
}

.con-like1 .checkmark1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.con-like1 .outline1,
.con-like1 .filled1 {
    fill: var(--red);
    position: absolute;
}

.con-like1 .filled1 {
    animation: kfr-filled 0.5s;
    display: none;
}

.con-like1 .celebrate1 {
    position: absolute;
    animation: kfr-celebrate 0.5s;
    animation-fill-mode: forwards;
    display: none;
}

.con-like1 .poly1 {
    stroke: var(--red);
    fill: var(--red);
}

.con-like1 .like1:checked~.checkmark1 .filled1 {
    display: block
}

.con-like1 .like1:checked~.checkmark1 .celebrate1 {
    display: block
}

@keyframes kfr-filled {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes kfr-celebrate {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 0.8;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
        display: none;
    }
}

@keyframes kfr-filled1 {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes kfr-celebrate1 {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 0.8;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
        display: none;
    }
}

.old-price {
    font-size: 22px;
    position: relative;
    color: #858585;
}

.old-price:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #858585;
    left: 0;
    top: 30%;
    transform: rotate(-35deg);
}

/* .heartEmoji {
    margin-left: auto;
} */


button.viewButton {
    color: #E4510B;
    cursor: pointer;
}

button.viewButton:disabled {
    cursor: not-allowed;
    /* Show not-allowed cursor on hover */
}

/* .button1{
    margin
} */

.accordion-header.active {
    background-color: initial !important;
    border-color: initial !important;
}

.dropicon {
    right: 19px !important;
    top: 50% !important;
}


.product-bulk {
    position: relative;
    display: inline-block;
}

.product-bulk-content {
    color: blue;
}

.hover-icon {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.hover-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1;
    top: 100%;
    /* Positioned right below the icon */
    left: 0;
    width: max-content;
    /* Adjust to the content's width */
}

/* Show hover content when hovering over the icon */
.hover-icon:hover .hover-content {
    display: block;
}

.myDIV {
    color: blue;
}

.hide {
    display: none;
}

.myDIV:hover+.hide {
    display: block;
    color: Gray;
}

.upload-butn {
    border: 1px solid;
    border-radius: 5px;
    background: #e44324;
    color: white;
}

.upload-butn:hover {
    border: 1px solid;
    border-radius: 5px;
    background: #b0290e;
    color: white;
}


.custom-switch:checked::after {
    display: none;
}

/* Custom class to hide the scrollbar */
.hide-scrollbar {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Edge */
}