.statusUpdate1{
    color: #E4510B;
    background-color: transparent !important;
    /* border-radius: 5px; */
    font-weight: 700;
    font-size: large;
    border: none !important;
    margin-left: 20px;
    cursor: pointer;
    height: 35px;
}
.statusUpdate2{
    color: #BBBBBB;
    background-color: transparent !important;
    /* border-radius: 5px; */
    font-weight: 700;
    font-size: large;
    border: none !important;
    margin-right: -20px;
    height: 35px;
}
.statusUpdate3{
    color: #BBBBBB;
    background-color: transparent !important;
    /* border-radius: 5px; */
    font-weight: 700;
    font-size: large;
    border: none !important;
    margin-left: 20px;
    margin-right: -20px;
    height: 35px;
}
.papa{
    font-size: 20px;
    font-weight: 700;
    font-family: Lato;
}
.title{
    color: #262525;
    opacity: 0.9;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Lato';
}
.title1{
    color: #262525;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Lato';
}