.pagination {
    margin-top: 30px;
}
.fixed-button {
  position: fixed;
  bottom: 10px; /* Adjust as needed */
  right: 8%;  /* Adjust as needed */
  z-index: 1000; /* Make sure it's above other elements */
  background-color: white;
}

.pagination .page-link {
    color: #000000;
    border: 1px solid #C1C1C1;
    /* border-radius: 50%; */
    width: 45px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25%;
}

.pagination .page-link:hover {
    background-color: #e4510b;
    color: #f5f2f2;
}

.pagination .page-item.active .page-link {
    background-color: #e4510b;
    border-color: #e4510b;
    color: #f5f2f2;
}

.page-link {
    display: none;
}

/* Style the switch */
/* Custom Switch Styles */
.switch-label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
  border: 2px solid red; /* Red border when unchecked */
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 1px;
  background-color: red; /* Red dot when unchecked */
  transition: 0.4s;
  border-radius: 50%;
}

/* When the input is checked */
.switch-input:checked + .slider {
  background-color: #4caf50; /* Green when active */
  border: 2px solid #4caf50; /* Green border when active */
}

/* Move the dot to the right when checked */
.switch-input:checked + .slider:before {
  transform: translateX(20px);
  background-color: white; /* White dot when active */
}

  