.cart-summary {
    border: 1px solid rgb(207, 207, 207);
    padding: 15px;
}

.cart-summary-para {
    color: rgb(102, 102, 102);
}

.cart-summary-voucher {
    background-color: rgb(237, 237, 237);
}

.order-now-btn.disabled,
.order-now-btn:hover.disabled {
    filter: blur(10px);
    /* Apply blur effect */
    cursor: not-allowed;
    /* Disable hover cursor */
}

.order-now-btn {
    height: 50px;
    width: 100%;
    background-color: #e4510b;
    border-radius: 5px;
    border: none;
    color: white;
    transition: transform 0.3s, box-shadow 0.3s;
}

.order-diasble-btn {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    background-color: #cf7c56;
    border: none;
    color: white;
    transition: transform 0.3s, box-shadow 0.3s;
}

.order-now-btn.disabled-btn {
    background-color: #cccccc;
    /* Disabled color */
    color: #666666;
    cursor: not-allowed;
}

.order-now-btn:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    background-color: #df4700;
}

.order-now-btn.disabled-btn:hover {
    box-shadow: none;
    /* No shadow on hover */
    background-color: #cccccc;
    /* Keep the disabled color */
}

.cart-card {
    border: 1px solid rgb(207, 207, 207);
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.cart-title {
    color: #333333;
}

.cart-left {
    background-size: cover;
}

@media (max-width: 568px) {

    .cart-left {
        margin-right: -50px;
        margin-bottom: 15px;
        width: 100%;
        height: 200px;
    }

    .my-order-search-btn {
        font-size: 14px;
    }

    .my-order-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .carda {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .heaa {
        margin-top: 20px;
    }

    .state {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .delivery {
        margin-top: 10px;
    }
}

.cart-quantity {
    color: rgb(102, 102, 102);
}



@media (max-width: 768px) {
    .cart-card {
        flex-direction: column;
    }

    .cart-left {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%;
        height: 200px;
    }
}

.empty-card-img {
    height: 400px;
}

@media (max-width: 768px) {
    .empty-card-img {
        height: 300px;
    }
}

.editQuantityInput {
    height: 30px;
    padding: 5px;
    width: 100px;
    border-radius: 5px;
    border: 1px solid #333333;
}

.editquantityBtn {
    height: 40px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: green;
}



@media (max-width: 768px) {
    .shiping {
        margin-top: 0;
    }
}




.ShippingAdress {
    padding: 5px;
}

/* rating stars */

.rating {
    display: inline-block;
    margin-top: -15px;
}

.rating input {
    display: none;
}

.rating label {
    float: right;
    cursor: pointer;
    color: #ccc;
    transition: color 0.3s;
}

.rating label:before {
    content: '\2605';
    font-size: 30px;
    display: inline-block;
}

.rating input:checked~label,
.rating label:hover,
.rating label:hover~label {
    color: rgb(253, 181, 66);
    transition: color 0.3s;
}



/* New Design css */

.filtedr-con {
    border: 1px solid rgb(225, 225, 225);
    border-radius: 16px;
}

.my-order-search {
    height: 46px;
    width: 100%;
    border: 1px solid rgb(225, 225, 225);
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 4px;
}

.my-order-search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    border: none;
    height: 46px;
    gap: 5px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #df4700;
    color: white;
    border-radius: 4px;
}

.my-order-card {
    border: 1px solid rgb(225, 225, 225);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.my-order-img {
    height: 120px;
    width: 120px;
    border-radius: 4px;
}

.disc-container-1 {
    padding: 4px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    border-radius: 20px;
    font-weight: 600;
}

.disc-1 {
    height: 8px;
    width: 8px;
    border-radius: 50%;

}

.disc-con-clr-1 {
    color: #FA9431;
    background-color: rgba(250, 148, 49, 0.16);
}

.disc-clr-1 {
    background-color: #FA9431;
}

.disc-con-clr-2 {
    color: #37A003;
    background-color: rgba(55, 160, 3, 0.16);
}

.disc-clr-2 {
    background-color: #37A003;
}

.disc-con-clr-3 {
    color: #DD0E0E;
    background-color: rgba(221, 14, 14, 0.16);
}

.disc-clr-3 {
    background-color: #DD0E0E;
}

.disc-con-clr-4 {
    color: #685DD8;
    background-color: rgba(104, 93, 216, 0.16);
}

.disc-clr-4 {
    background-color: #685DD8;
}

.disc-con-clr-5 {
    color: #00BBD1;
    background-color: rgba(0, 187, 209, 0.16);
}

.disc-clr-5 {
    background-color: #00BBD1;
}