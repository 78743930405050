.terms-para {
    font-weight: 400;
    font-style: normal;
    font-family: 'Lato';
    white-space: pre-wrap;
    color: #5D5D5D;
    line-height: 1.7em;
    font-size: 16px;
    text-decoration-line: none;
}

.bullet-point {
    list-style-type: disc;
    margin-top: 30px;
    display: list-item;
    margin-left: 20px;
    font-weight: 500;
    font-family: "Lato";
    font-size: 18px;
    color: #2B2B2D;
}