.customization-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    width: 100%;
    height: 80px;
    padding-left: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.customization-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: white;
    height: 100%;
    width: 100px;
}

.tools-tab {
    width: 100px;
    height: 90px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333333;
    text-align: center;
    cursor: pointer;
}

.tools-tab:hover {
    color: #E45D25;
}

.tools-tab-active {
    background-color: #E45D25;
    color: white;
    width: 120px;
    padding-right: 20px;
    border-radius: 12px;
    transition: all 300ms ease-out;
}

.tools-tab-active:hover {
    color: white;
}

.tools-tab-title {
    font-size: 12px;
}

.cursor-action {
    cursor: pointer !important;
}

/* Existing styles */
.step-tab {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-tab-count {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #878787;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.step-tab-count-active {
    background-color: #E45D25;
    transition: all 300ms ease-out;
}

.step-tab-title {
    font-size: 14px;
    /* font-weight: 600; */
    padding: 0px;
    margin: 0px;
    color: #333333;
    margin-top: 3px;
}

.step-tab-title-active {
    color: #E45D25;
    transition: all 300ms ease-out;
}

/* New hover styles applied on parent */
.step-tab:hover .step-tab-count {
    background-color: #E45D25;
}

.step-tab:hover .step-tab-title {
    color: #E45D25;
}

.step-tab-bar {
    width: 0%;
    height: 4px;
    background-color: #E45D25;
    position: absolute;
    bottom: 0;
    transition: all 300ms ease-out;
}

.step-tab-bar-active {
    width: 100%;
}


/* Customization page styles */

.customization-container {
    height: 100vh;
    padding-left: 100px;
    padding-top: 80px;
    padding-bottom: 72px;
    height: 100%;
    transition: all 300ms ease-in-out;
}

@media (max-width: 1000px) {
    .customization-container {
        height: 100vh;
        padding-left: 0px;
        padding-top: 80px;
        padding-bottom: 80px;
        height: 100%;
        transition: all 300ms ease-in-out;
    }
}


.tools-container {
    min-width: 450px;
    max-width: 450px;
    height: 579px;
    overflow: auto;
    background-color: #f9f9f9;
    padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 50px;

    /* border-right: 1px solid gray; */
}

.transition-eff {
    transition: all 300ms ease-in-out;
}

.customization-portion {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    transition: all 300ms ease-in-out;
    overflow: auto;
}

.toolbar-undo-redo {
    position: absolute;
    left: 20px;
    top: 20px;
}



.undo-redo-btn {
    background-color: white;
    border-radius: 6px;
    border: none;
    padding: 3px 8px;
    font-size: 12px;
}

.undo-redo-btn:hover {
    color: #E45D25;
}

@media (max-width: 568px) {
    .toolbar-undo-redo {
        position: absolute;
        left: 10px;
        top: 0px;
    }

    .undo-redo-btn {
        background-color: white;
        border-radius: 6px;
        border: none;
        padding: 2px 6px;
        font-size: 10px;
    }

    .customization-portion {
        margin-top: 0px;
    }
}

.front-back-tab {
    position: absolute;
    right: 20px;
    top: 20px;
}


.front-back-con {
    color: #515050;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid transparent;
    cursor: pointer;
}

.tab-img-front-back {
    width: 70px;
    object-fit: cover;
    border-radius: 10px;
}

.front-back-con:hover {
    transition: all 300ms ease-in-out;
    background-color: #E45D25;
    color: white;
    border: 1px solid #E45D25;
}

.front-back-con-active {
    background-color: #E45D25;
    color: white;
    border: 1px solid #E45D25;
}

@media (max-width: 568px) {
    .front-back-tab {
        position: absolute;
        right: 10px;
        top: 0px;
    }

    .front-back-con {
        color: #515050;
        font-weight: 600;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        border: 1px solid transparent;
        cursor: pointer;
    }

    .tab-img-front-back {
        width: 60px;
        object-fit: cover;
        border-radius: 10px;
    }

    .front-back-con:hover {
        transition: all 300ms ease-in-out;
        background-color: #E45D25;
        color: white;
        border: 1px solid #E45D25;
    }

    .front-back-con-active {
        background-color: #E45D25;
        color: white;
        border: 1px solid #E45D25;
    }
}

.next-btn-con {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.next-step-btn {
    background-color: #E45D25;
    color: white;
    border: none;
    border-radius: 28px;
    font-weight: 500;
    font-size: 16px;
    padding: 12px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.next-step-btn-2 {
    background-color: #E45D25;
    color: white;
    border: none;
    border-radius: 28px;
    font-weight: 500;
    font-size: 15px;
    padding: 10px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.next-step-btn:hover {
    background-color: #e5703f;
}

.next-step-btn-outline {
    background-color: white;
    color: #e5703f;
    border: 1px solid #e5703f;
    border-radius: 28px;
    font-weight: 500;
    font-size: 15px;
    padding: 8px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.next-step-btn:hover {
    background-color: #e5703f;
}

.step-container-1-active {
    min-width: 650px;
    max-width: 650px;
    overflow: auto;
    background-color: #f9f9f9;
    transition: all 300ms ease-in-out;
}

@media (max-width: 1000px) {
    .step-container-1-active {
        min-width: fit-content;
        max-width: fit-content;
        overflow: auto;
        background-color: #f9f9f9;
        transition: all 300ms ease-in-out;
    }
}


.quick-access-front-canvas {
    position: absolute;
    top: 140px;
    left: 40%;
}

.quick-access-back-canvas {
    position: absolute;
    top: 140px;
    left: 35%;
}

.quick-access-back-canvas-1 {
    position: absolute;
    top: 140px;
    left: 40%;
}

.ruller-img-canvas {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 579px;
    width: 512px;
    z-index: 1;
}

.customization-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    width: 100%;
    height: 72px;
    padding-left: 100px;
    padding-right: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 10;
}


/* Slim custom scrollbar for a specific class */
.custom-scrollbar {
    overflow: auto;
    /* Enable scrolling */
    scrollbar-width: thin;
    /* Firefox: slim scrollbar */
    scrollbar-color: #cccccc #f1f1f1;
    /* Firefox: thumb and track colors */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
    /* Width of the vertical scrollbar */
    height: 6px;
    /* Height of the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the scrollbar track */
    border-radius: 10px !important;
    /* Rounded edges for the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded edges for the thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Darker color on hover */
}

.edit-icon {
    background-color: #dadada;
    padding: 2px 10px;
    border-radius: 6px;
    color: #333333;
    font-size: 12px;
}

.edit-delete-icon {
    position: absolute;
    top: 0px;
    left: 30%;
}

@media (max-width: 768px) {
    .edit-delete-icon {
        position: absolute;
        top: 0px;
        left: 41%;
    }
}

@media (max-width: 568px) {
    .edit-delete-icon {
        position: absolute;
        top: 0px;
        left: 32%;
    }
}



.nxt-btn-con {
    position: absolute;
    bottom: 20px;
    z-index: 200;
}