.productCategoryBtn {
    height: 44px;
    width: 146px;
    border: none;
    background-color: #e4510b;
    color: white;
    font-weight: bold;
    border-radius: 8px;
}

.switch {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.switch .switch-label {
    cursor: pointer;
}

.switch .switch-input {
    appearance: none;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: inset 0 0 0 1px #c9c9c9;
    background-color: #dadada;
    width: 3rem;
    min-width: 3rem;
    padding: 0.125rem;
    border-radius: 3rem;
    cursor: pointer;
    transition: background-color 0.75s ease, box-shadow 0.35s ease-in;
}

.switch .switch-input:before {
    position: relative;
    content: "";
    display: inline-block;
    border-radius: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.32);
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.175);
}

.switch .switch-input:active:not(:disabled):before {
    width: 1.75rem;
}

.switch .switch-input:hover:not(:disabled) {
    box-shadow: inset 0 0 0 1rem #c9c9c9;
}

.switch .switch-input:focus,
.switch .switch-input:focus-within {
    box-shadow: 0 0 0 0.125rem #4caf30;
    outline: none;
}

.switch .switch-input:focus:before,
.switch .switch-input:focus-within:before {
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.4);
}

.switch .switch-input:checked {
    background-color: #5dca3e;
    box-shadow: inset 0 0 0 1px #5dca3e;
}

.switch .switch-input:checked:before {
    transform: translate3d(calc(100% - 0.5rem), 0, 0);
}

.switch .switch-input:checked:active:not(:disabled):before {
    margin-left: -0.5rem;
}

.switch .switch-input:checked:hover:not(:disabled) {
    box-shadow: inset 0 0 0 1rem #459f2c;
}

.switch .switch-input:checked:focus,
.switch .switch-input:checked:focus-within {
    box-shadow: 0 0 0 0.125rem #4caf30;
}

.switch .switch-input:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    user-select: none;
}

.switch .switch-input:disabled:before {
    background-color: #f8f8f8;
}

.total-count {
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 50%;
    color: white;
    background-color: #e4510b;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.css-13cymwt-control {
    height: 45px;
}