.cart-left-pic {
    width: 200px;
    height: 200px;
    background-size: cover;
    margin-right: 20px;
}
.stage1{
    border-radius: 50%;
    border: 2px solid #E4510B !important;
    color:  #E4510B;
    font-size: 20px;
    line-height: 35px;
    width: 30px;
    height: 30px;
}
.coupon{
  text-decoration: none;
  border: none;
  background: transparent;
  color: #E4510B;
}
.coupon:hover{
  text-decoration: underline;
  color: #E4510B;
}
.Apply-button{
  background: #E4510B;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 130px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Applied-button{
  background: #0be424;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 130px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coupon-input{
 width: 80%;
 border: 1px solid #e7e9ec; 
 border-radius: 10px;
 padding-left: 15px;
 padding-right: 15px;
}
.coupons{
  border: 1px solid black;
  background-color:#cccdce;
  border-radius: 15px;
  margin-top: 30px;
  padding: 30px;
}
.coupondescripiton{
  font-size: 18px;
  color: #626b7f;
}
.coupon-code{
  border-top: 1px solid #cacdd1;
  border-bottom: 1px solid #cacdd1;
}
.coupon-tag{
  background-color: #eff1f2;
  padding: 10px;
  margin-top: 10px;
}
.stage2{
    border-radius: 50%;
    color: white;
    background: #E4E4E4;
    border: 2px solid #E4E4E4;
    font-size: 20px;
    line-height: 35px;
    width: 30px;
    height: 30px;
}
.stage4{
  background-color: #E4510B;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 35px;
}
.stage3{
    border-radius: 50%;
    color: white;
    background: #E4E4E4;
    border: 2px solid #E4E4E4;
    font-size: 20px;
    line-height: 35px;
    width: 30px;
    height: 30px;
}
.stage-con1{
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: #E4510B;
}
.stage-con2{
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: #262525;
    margin-right: 25px;
}
.stage-con3{
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: #262525;
    margin-right: 10px;
}
.radio {
  position: relative;
  appearance: none;
  width: 20px !important; 
  height: 20px !important; 
  background-color: #fff;
  border: 2px solid #E4510B;
  border-radius: 100%;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  box-sizing: border-box;

  /* Add the inner circle */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px; /* Fixed size for the inner circle */
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    transition: box-shadow 0.3s ease;
  }
}

.radio:checked {
  background-color: #E4510B;
}

.radio:checked::before {
  box-shadow: inset 0 0 0 7px #fff; /* Fixed size for the inner circle shadow */
}

input[type="checkbox"] {
  appearance: none; /* Remove default checkbox */
  width: 30px; /* Adjust size */
  height: 30px; /* Adjust size */
  border: 2px solid #E4510B;
  border-radius: 4px; /* Optional: for rounded corners */
  position: relative;
  cursor: pointer;
}

/* Remove the outline and blue shadow */
/* input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid #E4510B; 
} */
input[type="text"]:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid #242322; /* Remove the default blue shadow */
}

/* Style the checkmark when checkbox is checked */
input[type="checkbox"]:checked {
  background-color: #E4510B;
  border-color: #E4510B;
}

input[type="checkbox"]:checked::after {
  content: '\2713'; /* Unicode character for the tick mark */
  color: white !important; /* Ensure the tick mark is white */
  font-size: 16px; /* Adjust size as needed */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.proces{
  height: 5px;
  width: 46%;
  background: #E4510B;
  border: none;
}
.addcartproduct{
  font-size: 20px;
  color: #E4510B;
  font-weight: 500;
  line-height: 20px;
}

@media (max-width:1280px){
  .stage-con1{
    margin-left: -15px;
  }
  .stage-con2{
    margin-left: -15px;
  }
  .stage-con3{
    margin-right: -15px;
  }
}
@media (max-width:1024px){
  .stage-con1{
    margin-left: -20px;
  }
  .stage-con2{
    margin-left: -15px;
  }
  .stage-con3{
    margin-right: -20px;
  }
}
@media (max-width:568px){
  .stage-con1{
    font-size: 14px;
  }
  .stage-con2{
    font-size: 14px;
  }
  .stage-con3{
    font-size: 14px;
  }
  .proces{
    width: 38%;
  }
  .addcartproduct{
    font-size: 16px;
  }
  .stage1, .stage2, .stage3, .stage4{
    width: 30px;
    height: 30px;
  }
}