/* ImageSlider.css */
.image-slider {
    display: flex;
    gap: 20px;
}

.thumbnail-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    overflow: hidden;
    gap: 8px;
}

.thumbnail-container img {
    width: 100%;
    height: 80px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 15px;
}

.selected-thumbnail {
    border: 3px solid rgb(0, 0, 0) !important;
    /* Highlight the selected thumbnail */
}

.main-image-container {
    display: flex;
    justify-content: center;
    position: relative;
    /* To position the magnified image properly */
}

.main-image {
    /* max-width: 100%; */
    /* max-height: 500px; */

    margin-bottom: 15px;
}

/* .main-image {
    max-width: 100%;
    max-height: 500px;
    margin-bottom: 15px;
} */

/* .main-image-container {
    width: 100%;
    height: auto;
} */

.main-image-1 {
    width: 100%;
    object-fit: contain;
}

.floating-magnify {
    position: absolute;
    top: 10px;
    max-width: 600px;
    max-height: 600px;
    left: 60px;
}

.thumbnail-container {
    display: flex;
    gap: 10px;
    padding: 10px;
}

.selected-thumbnail {
    border: 2px solid #000;
}


.main-image-container {
    position: relative;
    margin-bottom: 20px;
    /* Adds space below main image for thumbnails */
}

.floating-magnify {
    position: fixed;
    /* Ensures the magnified image stays in a floating window */
    top: 10%;
    right: 5%;
    width: 300px;
    z-index: 1000;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.thumbnail-container {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    margin-top: 20px;
    /* Adds space above thumbnails */
}

.thumbnail-container img {
    cursor: pointer;
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 2px solid transparent;
    transition: border 0.3s ease;
}

.thumbnail-container img.selected-thumbnail {
    border-color: #007bff;
}

.mobile-thumbnail-holder {
    position: absolute;
    right: 0;
    top: 0
}

.thumbnail-container-1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-x: auto;
}

.thumbnail-container-1 img {
    cursor: pointer;
    width: 60px;
    /* height: 60px; */
    border-radius: 10px;
    object-fit: contain;
    border: 2px solid transparent;
    transition: border 0.3s ease;
}

.thumbnail-container-1 img.selected-thumbnail {
    border-color: #007bff;
}

@media (max-width:1008px) {
    .thumbnail-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        overflow: hidden;
        gap: 8px;
    }

    .thumbnail-container img {
        width: 80px;
        height: 80px;
        cursor: pointer;
        border: 2px solid #ccc;
        border-radius: 15px;
    }
}

.slider img {
    /* width: 100%; */
    border-radius: 10px;
}

.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: rgb(255, 68, 68);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: rgb(255, 68, 68) !important;
}

.imagecenter {
    width: 200px !important;
    height: 200px;
}

.carousel-control-next-icon {
    display: none !important;
}

.carousel-control-prev-icon {
    display: none !important;
}

.carousel-indicators {
    bottom: 5%;
}

.main-image {
    position: relative;
}