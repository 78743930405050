.footer-bg {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    padding-bottom: 30px;
    position: relative;
    background: #E4510B;
    color: white;
    position: relative;
}

.footer-award {
    height: 80px;
    width: 70px;
}

.footer-con {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-head {
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 20px;
}

.footer-para a {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

.footer-para1 a {
    text-decoration: none;
    color: #08a2e5;
    font-size: 18px;
}

.footer-para a:hover {
    color: black;
}

.newsletter-con {
    padding-left: 5%;
    padding-right: 5%;
    position: absolute;
    margin-top: -630px;
    background-color: #FFE0B8;
    border-radius: 12px;
    width: 90%;
}

.email-head {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
    color: #292929;
}

.email-para {
    color: #292929;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    margin-left: -200px;
}

.subscribe-btn {
    background: #3BB045;
    color: white;
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 4px;
}

.email-input {
    width: 300px;
    border: none;
    padding-left: 20px;
}




@media (max-width: 568px) {

    .email-img {
        display: none;
    }

    .email-head {
        font-size: 19px;
    }

    .email-para {
        margin-left: 0px;
        font-size: 14px;
    }

    .email-input {
        width: 70%;
    }

    .subscribe-btn {
        width: 100%;
    }

    .newsletter-con {
        margin-top: 80px !important;
    }

    .whole-letter {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}

@media (max-width: 1024px) {
    .email-img {
        width: 100%;
    }

    .newsletter-con {
        margin-top: -600px !important;
    }
}

@media (max-width: 768px) {
    .email-img {
        display: none;
    }

    .subscribe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .newsletter-con {
        margin-top: -950px !important;
    }
}

@media (max-width: 835px) {
    .email-img {
        display: none;
    }

    .subscribe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .newsletter-con {
        margin-top: -900px;
    }
}

.footer-bottom-head {
    color: white;
    font-size: 14px;
}

.footer-bottom-para a {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

.footer-bottom-para a:hover {
    color: black;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-bottom-img {
    display: flex;
}

.whatsappLink {
    color: black;
}

.footer-whatsapp {
    padding-top: 10%;
    margin-left: 5px;
    bottom: 80px !important;

}


.footer-log {
    background: #7777777e;
    padding: 10px 20px;
    width: 100%;

}