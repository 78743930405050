.cus-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 86px;
    min-width: 86px;
    color: #8C8C8C;
    cursor: pointer;
    text-align: center;
}

.cus-tab-active {
    background-color: #FDF5F2;
    color: #E45D25;
    border-radius: 8px;
}

/* Overlay for background dimming when drawer is open */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Light shade */
    z-index: 999;
    /* Make sure it's above other elements */
}

/* Drawer styling */
.drawer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    transition: transform 0.3s ease-in-out;
    /* Smooth transition for opening/closing */
    border-radius: 10px 10px 0 0;
    height: 60%;
    /* Adjust as needed */
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    /* Above the overlay */
}

/* Drawer handle for user to touch and swipe */
.drawer-handle {
    width: 60px;
    height: 5px;
    background-color: #ccc;
    border-radius: 10px;
    margin: 10px auto;
    /* Centered horizontally */
}

/* Drawer content */
.drawer-content {
    padding: 20px;
}