.tool-title {
    color: #E45D25;
    font-size: 13px;
    font-weight: 600;
}

.tool-sub-title {
    color: #333333;
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
}

.tool-desc {
    color: #a7a7a7;
    font-size: 14px;
}

.tool-bar-line {
    background-color: #d7d7d7;
    height: 1px;
    width: 100%;
}

.tool-product-con {
    margin-top: 20px;
    border-radius: 12px;
}

.tool-product-name {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
}

.tool-change-product {
    color: #E45D25;
    text-decoration: underline;
    font-size: 13px;
}

.tool-product-img-con {
    width: 80px;
    height: 80px;
    padding: 10px;
    border: 1px solid #E45D25;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tool-product-img {
    border-radius: 8px;
}

.field-type-label {
    font-size: 14px;
    font-weight: 600;
}

.field-option-select {
    border-radius: 10px !important;
    font-size: 14px !important;
}

.field-option-label {
    font-size: 14px;
}

.upload-img-label {
    color: #E45D25;
    cursor: pointer;
}

.input-custom-placeholder::placeholder {
    color: rgb(203, 203, 203) !important;
}

.tool-upload-con {
    border: 2px dashed rgb(197, 197, 197);
    background-color: #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tool-upload-label {
    font-size: 14px;
}

.tool-upload-btn {
    margin-top: 10px;
    background-color: #E45D25;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
}

.tool-upload-btn:hover {
    transition: all 300ms ease-in-out;
    background-color: #e35315;
}

.text-editor-label {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    width: 150px;
}

/* General styling for the number input */
.number-input {
    width: 55px !important;
    /* Set the width */
    -moz-appearance: textfield;
    /* Remove arrows for Firefox */
}

/* Remove arrows for Chrome, Safari, Edge, and Opera */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.text-add-btn {
    width: 100%;
    border: none;
    border-radius: 6px;
    background-color: #e35315;
    color: white;
    padding: 10px;
}

.text-add-btn-outiline {
    width: 100%;
    border: 1px solid #e35315;
    border-radius: 6px;
    background-color: #ffffff;
    color: #e35315;
    padding: 10px;
}

.clipart-images {
    width: 100px;
    height: 100px;
    object-fit: cover;
    background-color: white;
    border-radius: 5%;
    cursor: pointer;
}

.image-con-cus {
    position: relative;
    border-radius: 12px;
}

.img-cus {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 12px;
}

.image-con-cus-cancel {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    padding-bottom: 3px;
    cursor: pointer;
}

.image-con-cus-cancel:hover {
    transition: all 200ms ease-in;
    width: 29px;
    height: 29px;
}

.size-btn {
    border: 2px solid rgb(207, 207, 207);
    font-size: 15px;
    padding: 10px 20px;
    background-color: white;
    font-weight: 600;
}

.size-btn-active {
    border: 2px solid #e35315;
}

.text-add-btn-unactive {
    background-color: #dd9070;
}

.template-img-1 {
    border-radius: 8px;
}

.template-text-1 {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
}

.template-con-1 {
    padding: 0px;
    cursor: pointer;
}

.template-con-1:hover .template-text-1 {
    color: #e35315;
    transition: all 300ms ease-in-out;
}