.mobile-customization-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    width: 100%;
    height: 80px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.mobile-customization-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
}


.logo-mobile {
    width: 80px;
}

@media (max-width: 568px) {
    .logo-mobile {
        width: 60px;
    }
}

.mobile-tool-label {
    font-size: 15px;
    width: 100%;
}

.cus-tab-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 75px;
    min-width: 120px;
    padding: 0px 10px;
    color: #333333;
    cursor: pointer;
    text-align: center;
}

.cus-tab-1-active {
    background-color: #E45D25;
    color: white;
    border-radius: 8px;
}

.mobile-tools-list {
    border-top: 1px dashed gray;
}