.view-order-card {
    border: 1px solid rgb(225, 225, 225);
    border-radius: 16px;
}

.tick-green {
    background-color: #37A003;
    border-radius: 50%;
    padding: 4px;
    color: white;
}

.tick-normal {
    color: #D9D9D9;
    background-color: #D9D9D9;
    border-radius: 50%;
    padding: 4px;
}

.tick-red {
    color: white;
    background-color: #E40F0A;
    border-radius: 50%;
    padding: 4px;
}

.bar-line {
    height: 8px;
    width: 180px;
}

.bar-line-1 {
    height: 8px;
    width: 600px;
}

.bar-line-clr-1 {
    background-color: #D9D9D9;
}

.bar-line-clr-2 {
    background-color: #37A003;
}

.bar-line-clr-3 {
    background-color: #E40F0A;
}


.order-cancel-btn {
    border: 1px solid #E4510B;
    color: #E4510B;
    font-weight: 600;
    background-color: white;
    padding-left: 18px;
    padding-right: 18px;
    height: 38px;
    border-radius: 4px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.order-change-btn {
    border: 1px solid #E4510B;
    color: #E4510B;
    font-weight: 600;
    background-color: white;
    padding-left: 18px;
    padding-right: 18px;
    height: 38px;
    border-radius: 4px;
}

.confirm-cancel-btn {
    background: #E4510B;
    border: 1px solid #E4510B;
    color: white;
    padding-left: 18px;
    padding-right: 18px;
    height: 38px;
    border-radius: 4px;
    font-weight: 600;
}



.cancelOrderImg {
    width: 400px;
    height: 300px;
}