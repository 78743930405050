.aboutUs-logo {
    width: 100px;
    height: 100px;
}

.aboutUs-para {
    font-weight: 500;
    font-style: normal;
    white-space: pre-wrap;
    color: rgb(98, 107, 127);
    line-height: 1.7em;
    font-size: 16px;
    text-decoration-line: none;
}

.about1 {
    background-image: url("../../../assets/images/banner/about-bg.png");
    /* background-position: center;s */
    background-color: #FAFAFA;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 250px;

}

.aout1-pa {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins;
    align-items: center;
    color: #5D5D5D;
}

.about-h {
    font-size: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;

}

.about1-img {
    width: 100%;
    height: 100%;
}

.choose {
    background: #FFF2F2;

}

.cat-img {
    width: 40%;
}

.imgsc1 {
    justify-content: end;
}

.imgsc1,
.imgsc2 {
    width: 350px;
}

.choosebg {
    width: 300px;
    height: 300px;
}

.chooseperson {
    width: 420px;
    height: 420px;
    margin-top: -450px;
}

.chooseppp {
    margin-top: 150px;
}

.choose-box {
    width: 100%;
    height: 240px;
    background: #FFFFFF;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1);
}

.choose-img {
    width: 30px;
    height: 30px;
    margin-top: 20px;
}

.choose-head {
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    color: #333333;
}

.choose-para {
    font-size: 16px;
    font-weight: 400;
    font-family: Lato;
    line-height: 25px;
    color: #666666;
}

.gurante {
    background: #FAFAFA;
}

.gran-para {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #2B2B2B;
}

.how {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    color: #414141;
}

.how-h5 {
    color: #E4510B;
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    margin: 15px;
}

.how-para {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #666666;
    margin-left: 15px;
    margin-right: 15px;
}

@media (max-width: 568px) {
    .line {
        display: none !important;
    }

    .imgsc1,
    .imgsc2 {
        display: none;
    }

    .aout1-pa {
        text-align: center;
    }

    .order {
        padding-bottom: 40px;
    }

    .seaaa {
        margin-top: 30px;
    }

    .about1 {
        background-image: none;
        height: 200px;
    }

    .note {
        margin-bottom: 50px;
    }

    .choose-box {
        margin-top: 30px;
    }

    .chooseperson {
        width: 100%;
    }

    .about11 {
        display: none;
    }

    .bann {
        width: 280px !important;
    }
}

@media (max-width: 768px) {
    .line {
        display: none !important;
    }

    .imgsc1,
    .imgsc2 {
        display: none;
    }

    .choose-box {
        margin-top: 30px;
    }
}

@media (max-width: 1024px) {
    .line {
        display: none !important;
    }

    .choose-box {
        height: 330px;
    }

    .imgsc1,
    .imgsc2 {
        display: none;
    }
}

@media (max-width: 1280px) {
    .about-h {
        width: 180px;
    }
}