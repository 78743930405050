.drawing-area {
  position: absolute;
  z-index: 10;
  border: 1px solid #ccc;
}

.canvas-container {
  position: relative;
  user-select: none;
}

#tshirt-div {
  position: relative;
  background-color: #fff;
}

#canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  user-select: none;
  cursor: default;
}

.customize-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border: 1px solid #EEEEEE;
  background-color: #FFFFFF;
  height: 50px;
  padding-left: 2%;
  padding-right: 2%;
}

.design-bar {
  background-color: #FFFFFF;
  border-radius: 10px;
}

.design-icon {
  padding: 8px;
  height: 100px;
  cursor: pointer;
}

.color-picker {
  display: block;
}

.color-picker label {
  margin-bottom: 10px;
}

.color-swatches {
  display: flex;
  flex-wrap: wrap;
}

.clr-con {
  padding: 3px;
  border: 3px solid #CFCFCF;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  cursor: pointer;
}

.clr-con-active {
  padding: 3px;
  border: 3px solid #E45D25;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  cursor: pointer;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  border: 2px solid #CFCFCF;
}

.color-swatch-active {
  border: 2px solid white;
}

.add-color-btn {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  border: 1px solid black;
  width: 30px;
  height: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.color-picker-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.text-card {
  border: 1px solid black;
  /* cursor: pointer; */
  display: flex;
  padding: 10px;
  border-radius: 10px;
}

.selected-size {
  background-color: white;
  /* Change this color to your desired highlight color */
  color: #E45D25;
  border: 1px solid #E45D25;
}

.size {
  border: 1px solid #DFDFDF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  font-family: Lato;
}

.color-picker-modal button {
  padding: 10px 20px;
  margin: 5px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.heading-product {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: Lato;
  font-weight: 700;

}

.btn-save-customize {
  background-color: #e44324;
  height: auto;
  border: none;
  color: white;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
}

.customize-reset {
  color: #8C8C8C;
  font-family: Lato;
  font-size: 20px;
  line-height: 24px;
}

.customize-question {
  border: none;
  height: 41px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 5px;
}

.customize-product-container {
  background-color: #f0f0f0;
  height: 94vh;
  display: flex;
  flex-direction: row;
}

.left-container-img {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.left-container-img1 {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right-container-edit {
  width: 400px;
  background-color: white;
  padding: 20px;
}

.select-customize-label {
  font-size: 14px;
}

.customize-select {
  width: 100%;
  height: 40px;
}

@media (max-width: 568px) {
  .customize-header {
    flex-direction: column;
    height: 100%;
  }

  .customize-product-container {
    flex-direction: column;
    height: 100%;
  }

  .left-container-img {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right-container-edit {
    width: 100%;
  }
}

@media (max-width:786px) {
  .customize-product-container {
    flex-direction: column;
    height: 100%;
  }

  .left-container-img {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right-container-edit {
    width: 100%;
  }
}

.vertical-images {
  display: flex;
  margin-left: 100px;
  width: 80px;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.additional-class-name {
  background-color: #FDF5F2;
  color: #E45D25;
}

.logo-icon {
  color: #8C8C8C;

}

.logo-icon1 {
  color: #E45D25;
}

.name-number-box {
  width: 100%;
  height: 250px;
  background-color: white;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style-name-input {
  width: 100%;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.save-cls-btn {
  width: 100%;
  height: 46px;
  border-radius: 25px;
  border: none;
  background-color: #e44324;
  color: white;
}

.save-cls-btn-1 {
  width: 100%;
  border-radius: 25px;
  border: none;
  background-color: white;
  color: #8C8C8C;
}

.newwww {
  display: flex;
  justify-items: center;
}