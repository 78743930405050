/* .selectInput {

    font-family: 'Segoe UI', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 190px;
    position: relative;

}

.selectInputOptions {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 20px;
    width: 100%;
}

.selectInputselecting {
    font-size: 100%;
    position: absolute;
    left: 0;
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
    color: rgb(100, 100, 100);
}

.selectInput :is(Options:focus, Options:invalid)~Selecting {
    transform: translateY(-50%) scale(.9);
    margin: 0em;
    margin-left: 1.3em;
    padding: 0.4em;
    background-color: #e8e8e8;
}

.selectInput :is(Options:focus, Options:invalid) {
    border-color: rgb(150, 150, 200);

} */

.table{
    width: 1220px;
}
@media screen and (max-width: 1280px){
.table{
    width: 800px;
}
}
.materialProducts {
    /* padding-left: 5% !important; */
    margin-left: 25%;
    margin-top: 5%;
    /* border: 1px solid gray; */
}