.next-step-btn-1 {
    background-color: #E45D25;
    color: white;
    border: none;
    border-radius: 28px;
    font-weight: 500;
    font-size: 16px;
    padding: 12px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.selected-type-value {
    color: #E45D25;
    font-weight: 600;
    font-size: 13px;
    margin: 0px;
    padding: 0px;
    text-align: center;
    padding: 6px 12px;
}

.selected-con {
    border-radius: 10px;
    border: 1px dashed #E45D25;
}

.selected-type-option-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 6px 12px;
}

.summary-label {
    font-weight: 600;
}

.size-con {
    background-color: rgb(231, 230, 230);
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
}

/* Custom class to hide the scrollbar */
.hide-scrollbar {
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer */
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Edge */
}

@media (max-width: 768px) {
    .sizeQWidth {
        width: 700px;
    }
}

@media (max-width: 568px) {
    .sizeQWidth {
        width: 300px;
    }
}