.order-stats-card {
    background-color: white;
    width: 100%;
    padding: 16px;
    border-radius: 14px;
    color: #4B465C;
}

.order-table-con {
    background-color: white;
    border-radius: 14px;
}

.red-line {
    width: 3px;
    height: 20px;
    background-color: #E4510B;
    /* background-color: #f9ebdf; */
}

.line-hr-new {
    height: 1px;
    width: 100%;
    background-color: #EEEEEE;
}

.search-order {
    width: 330px;
    padding: 6px;
    padding-left: 10px;
    border: 1px solid#DFDFDF;
    border-radius: 4px;
}

.select-order {
    width: 200px;
    padding: 6px;
    padding-left: 10px;
    border: 1px solid#DFDFDF;
    border-radius: 4px;
}