.des1{
    padding-bottom: 10%;
    padding-left: 5%;
    padding-right: 5%;
}

@media (max-width:568px){
    .des1{
        padding-bottom: 30%;
    }
}
