.auth-button1{
    background: #e4510b;
    color: white;
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Lato';
   }
   
   .auth-button1.disabled {
       background-color: gray;   /* Gray background for disabled state */
       cursor: not-allowed;      /* Show not-allowed cursor */
       opacity: 0.6;             /* Slightly reduce opacity */
   }