.category-card-amt {
    color: #37A003;
    font-family: Lato;
    font-size: 24px;
    margin-top: -15px;
    font-weight: 600;
}

.banner1 {
    display: flex;
    justify-content: flex-end;
    margin-top: -450px;
}

.banner2 {
    margin-top: 70px;
}

.banner-container1 {
    color: #3A5198;
    font: Poppins;
    font-weight: 500;
    font-size: 55px;
    line-height: 82px;
}

.banner-container2 {
    color: #C10C44;
    font: Poppins;
    font-weight: 500;
    font-size: 24px;
}

.bannerContain {
    background: #FFECD2;
    height: 450px;
}

.work-icon {
    width: 130px;
    height: 130px;
    background: #FDF5F2;
    border-radius: 50%;
}

.work-text {
    font: Lato;
    font-size: 24px;
    font-weight: 600;
}

.custom-product {
    background: #FFEFE6;
    justify-content: center;
    display: flex;
}

.print-imgggg {
    background-image: url("../../../assets/images/banner/customback.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding-bottom: 100px;
}

@media (max-width: 568px) {
    .print-imgggg {
        padding-bottom: 50px;
    }
}

.print-h1 {
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
}

.print-para {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #686868;
}

.print-img {
    margin-bottom: 150px;
}

.strike {
    position: relative;
    display: inline-block;
    color: #cdcad2;
    margin-top: -15px;
}

.strike::after {
    content: '';
    position: absolute;
    top: 0;
    left: 30%;
    width: 100%;
    height: 2px;
    /* Thickness of the line */
    background-color: #cdcad2;
    /* Color of the line */
    transform: rotate(60deg);
    transform-origin: left center;
}

.print-btn {
    background: #E4510B;
    padding-right: 10px;
    padding-left: 10px;
    border: none;
    color: white;
    border-radius: 4px;
}

.printimg2 {
    width: 100px;
    height: 100px;
    border-radius: 12px;
}

.printimg45 {
    width: 130px !important;
    height: 130px !important;
}

.category-home {
    border-radius: 50%;
    background: white;
    /* border: 1px solid #E4510B; */
    display: flex;
    flex-direction: column;
    position: relative;
    width: 150px;
    height: 150px;
    align-items: center;
    justify-content: center;
    margin-left: 43px;
}

.category-home:hover {
    border: 1px solid #E4510B;
}

.category-home1 {
    border-radius: 50%;
    background: white;
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.category-text {
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
}

.category-type {
    padding-left: 8%;
    padding-right: 8%;
    margin-top: -75px;
    width: 100%;
}

.all-category {
    background: #F8F7FA;
    padding-bottom: 40px;
}

@media (max-width: 568px) {
    .all-category {
        background: #F8F7FA;
        padding-bottom: 20px;
    }
}

.categ11 {
    margin-top: 80px;
}

.printiii {
    display: flex;
    justify-content: flex-end;
}

.special-hover-style {
    left: 145px !important;
}

.printii-img {
    width: 400px;
}

.special-hover-style1 {
    margin-left: 23px;
}

/* @media (max-width: 768px) {
    .banner-container {
        display: none;
    }
} */

@media (max-width: 568px) {
    .banner-container {
        display: none;
    }

    .print-img {
        display: none;
    }

    .printiii {
        display: none;
    }

    .print-imgggg {
        position: relative;
        background: #FFEFE6;
    }

    .categ11 {
        margin-top: 10px;
    }

    .work-icon {
        width: 100px;
        height: 100px;
    }

    .work-text {
        font-size: 16px;
        font-weight: 600;
    }

    .br {
        display: none;
    }

    .arrow {
        display: none;
    }

    .category-type {
        display: none;
    }

    .banner1 {
        display: none;
    }

    .banner-container1 {
        font-size: 30px;
        width: 100%;
        line-height: 35px;
        margin-top: -45px;
    }

    .banner-container2 {
        font-size: 16px;
    }

    .bannerContain {
        height: 300px;
    }

    .img1 {
        display: none;
    }
}

@media (max-width: 768px) {
    .category-home {
        display: none;
        /* Hide all categories */
    }

    .category-home:nth-child(-n+4) {
        display: block;
        /* Show the first 5 categories */
    }

    .printiii {
        margin-top: 10px;
        justify-content: center;
    }

    .custom-product {
        height: auto;
    }

    .print-imgggg {
        background-image: none;
    }

    .arrow {
        display: none;
    }
}

@media (max-width: 1280px) {
    .arrow {
        width: 60px;
    }

    .printii {
        width: 460px;
        margin-top: -60px;
    }

    .printiii {
        margin-top: 50px;
    }

    .printii-img {
        width: 400px;
    }

    .printimg2 {
        width: 100px;
        height: 100px;
    }

    .printimg45 {
        width: 120px !important;
        height: 120px !important;
    }

    .br {
        display: none;
    }

    .category-type {
        padding-left: 2% !important;
        padding-right: 2% !important;
    }

    .category-type1 {
        justify-content: space-between;
    }

    .category-home {
        margin-left: 30px !important;
        width: 130px;
        height: 130px;
    }

    .special-hover-style1 {
        margin-left: 10px;
    }

    .special-hover-style {
        left: 55px !important;
    }
}

@media (max-width: 1100px) {
    .img1 {
        display: none;
    }

    .arrow {
        width: 30px;
    }

    .special-hover-style {
        left: 30px !important;
        width: 160px !important;
        height: 220px !important;
        margin-top: -60px !important;
    }

    .special-hover-style1 {
        margin-left: -4px;
        width: 150px !important;
        margin-top: -45px !important;
    }

    .category-home {
        margin-left: 18px !important;
        width: 130px;
        height: 130px;
    }

    .category-home1 {
        width: 25px !important;
        height: 25px !important;
    }

    .iconimg {
        width: 20px !important;
        height: 20px !important;
    }

    .printii {
        width: 460px;
    }

    .printiii {
        margin-top: 50px;
    }

    .printii-img {
        width: 460px;
        margin-left: 30px;
    }

    .printimg2 {
        width: 120px;
        height: 120px;
    }

    .printimg45 {
        width: 150px !important;
        height: 150px !important;
    }

    .br {
        display: none;
    }

    .category-type {
        padding-left: 2% !important;
        padding-right: 2% !important;
    }
}

@media (max-width: 768px) {
    .printii-img {
        margin-top: 90px;
        margin-left: 0px;
    }
}

@media (max-width: 1440px) {
    .category-type {
        padding-left: 7%;
        padding-right: 7%;
    }

    .special-hover-style {
        margin-left: -100px !important;
    }
}

@media (max-width: 1440px) {
    .category-type {
        padding-left: 7%;
        padding-right: 7%;
    }

    .special-hover-style {
        margin-left: -20px !important;
        margin-top: -50px !important;
    }

    .special-hover-style1 {
        margin-top: -50px !important;
    }
}

@media (max-width: 1536px) {
    .special-hover-style {
        margin-left: -0px !important;
        margin-top: -50px !important;
    }

    .special-hover-style1 {
        margin-top: -50px !important;
    }
}

@media (max-width: 834px) {
    .printiii {
        margin-top: 10px;
        justify-content: center;
    }
}

.explore {
    background: #E4510B;
    color: white;
    padding-right: 10px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    border-radius: 4px;
}

.explore:hover {
    background: #E4510B;
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 10px;
    height: 40px;
    border-radius: 4px;
}

.hover-category:hover {
    background-color: #FFEFE6;
    transition: all;
    animation-duration: 200;
    cursor: pointer;
}

.active-cat {
    background-color: #FFEFE6;
    transition: all;
    animation-duration: 200;
    cursor: pointer;
}

.product-img-rnd {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-bottom: 8px;
}

.title-product {
    width: 80%;
    font-size: 12px;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-btn-design-2 {
    background-color: #E4510B;
    color: white;
    border: none;
    padding: 7px;
    font-size: 14px;
    border-radius: 4px;
}

.modal-txt-design-2 {
    background-color: white;
    color: #E4510B;
    border: none;
    padding: 7px;
    font-size: 14px;
    border-radius: 4px;
}

.modal-btn-design-1 {
    background-color: white;
    color: black;
    border: 1px solid #E4510B;
    padding: 7px;
    font-size: 14px;
    border-radius: 4px;
}

.view-all-fast-1 {
    display: flex;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    justify-content: flex-end;
    color: #e4510b;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 568px) {
    .view-all-fast-1 {
        font-size: 18px;
    }
}


.category-list {
    height: 500px;
    overflow: auto;
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: #c8c8c8 #f0f0f0;
    /* Thumb color and track color */
}

/* Customize scrollbar for WebKit browsers */
.category-list::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

.category-list::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* Track background color */
}

.category-list::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Scrollbar thumb color */
    border-radius: 10px;
    /* Rounded corners for the thumb */
    border: 2px solid #f0f0f0;
    /* Adds space around the thumb */
}

.category-list::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Thumb color on hover */
}

.product-list-modal {
    height: 550px;
    overflow: auto;
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For IE and Edge */
}

/* For Chrome, Safari, and Edge */
.product-list-modal::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1490px) {
    .category-list {
        height: 450px;
        overflow: auto;
    }

    .product-list-modal {
        height: 500px;
        overflow: auto;
        scrollbar-width: none;
        /* For Firefox */
        -ms-overflow-style: none;
        /* For IE and Edge */
    }

}

@media (max-width: 1300px) {
    .category-list {
        height: 400px;
        overflow: auto;
    }

    .product-list-modal {
        height: 450px;
        overflow: auto;
        scrollbar-width: none;
        /* For Firefox */
        -ms-overflow-style: none;
        /* For IE and Edge */
    }
}