.cart-summary {
    border: 1px solid rgb(207, 207, 207);
    border-radius: 15px;
}

.cart-summary-para {
    color: rgb(102, 102, 102);
}

.cart-summary-voucher {
    background-color: rgb(237, 237, 237);
}

.order-now-btn {
    height: 50px;
    width: 100%;
    background-color: #e4510b;
    border: none;
    color: white;
    transition: transform 0.3s, box-shadow 0.3s;
}

.order-now-btn:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    background-color: #df4700;
}

.cart-card {
    border: 1px solid rgb(207, 207, 207);
    display: flex;
    flex-direction: row;
}

.cart-title {
    color: #333333;
}

.cart-left {
    width: 200px;
    height: 150px;
    background-size: cover;
    margin-right: 20px;
}

.cart-quantity {
    color: rgb(102, 102, 102);
    margin-top: -10px;
}
.success-modal-btn {
    height: 46px;
    width: 186px;
    font-size: 15px;
    border: none;
    color: white;
    background-color: #e4510b;
    border-radius: 35px;
}
.produ-img{
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 5px;
}

.cart-right {
    width: 100%;
}

.pppaa{
    padding-top: 0;
}
.check1{
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #E4510B;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

@media (max-width: 568px) {
    .cart-card {
        flex-direction: column !important;
    }

    .cart-left {
        margin-right: 0px;
        margin-bottom: 15px;
    }

    .cart-right {
        width: 100%;
        height: 100%;
        margin-top: 180px !important;
    }
    .produ-img{
        margin-right: 25px;
        width: 280px !important;
    }
    .pppaa{
        padding-top: 50px;
    }
    .check{
        padding-bottom: 25%;
    }
    .hrrr1{
        display: none;
     }
     .cent{
        justify-content: center !important;
        align-items: center !important;
        margin-top: 50px !important;
     }
}
@media (max-width: 768px) {
    .produ-img{
        width: 30%;
        height: 30%
    }
    .hrrr1{
        display: none;
     }
     .cent{
        justify-content: center;
        align-items: center;
        margin-top: 0px;
     }
     .cart-right{
        margin-top: 20px;
     }
}
@media (max-width: 1280px) {
    .hrrr1{
       width: 83% !important;
    }
}
@media (max-width: 1024px) {
    .hrrr1{
       width: 79% !important;
    }
}

.empty-card-img {
    height: 400px;
}

@media (max-width:568px) {
    .empty-card-img {
        height: 300px;
    }
}


/* .editQuantityInput {
    height: 40px;
} */

.editquantityBtn {
    height: 40px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: green;
}