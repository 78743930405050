.con-like {
    --red: rgb(255, 50, 50);
    position: relative;
    width: 35px;
    height: 35px;
    /* margin-right: 5%; */
    margin-top: 7px;
    margin-left: 7px;
}

.con-like .like {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
}

.explore1{
    background: #E4510B;
    color: white;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    height: 40px;
    border-radius: 4px;
}

.hea2{
    width: 580px;
    font: Lato;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    align-items: center;
    justify-content: center;
}
.hea1{
    width: 330px;
    font: Lato;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    align-items: center;
    padding-left: 30px;
}

.hea3{
    width: 200px;
    font: Lato;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    align-items: center;
    justify-content: center;
}

.hea4{
    width: 200px;
    font: Lato;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    align-items: center;
    justify-content: center;
}

.con-like .checkmark {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.con-like .outline,
.con-like .filled {
    fill: var(--red);
    position: absolute;
}

.con-like .filled {
    animation: kfr-filled 0.5s;
    display: none;
}

.con-like .celebrate {
    position: absolute;
    animation: kfr-celebrate 0.5s;
    animation-fill-mode: forwards;
    display: none;
}
.wish-para{
    width: 500px;
}

.con-like .poly {
    stroke: var(--red);
    fill: var(--red);
}

.con-like .like:checked~.checkmark .filled {
    display: block
}

.con-like .like:checked~.checkmark .celebrate {
    display: block
}
.contt{
    margin-left: 20px;
}
.padd{
    padding: 25px;
}

.table-responsive,
.table {
  overflow-y: visible !important;
}

.table-responsive {
  /* @apply block w-full overflow-x-auto; */
  width: 100%;
  overflow-x:auto; 
}
/* 
.table-responsive {
  /* @apply overflow-x-auto; 
  
} */

@keyframes kfr-filled {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes kfr-celebrate {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 0.8;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
        display: none;
    }
}
.tab{
    width: 100%;
}

@media (max-width:1280px){
    .hrrr{
        width: 77% !important;
    }
    .aout1-pa{
        padding-left: 5%;
        padding-right: 5%;
    }
}
@media (max-width:1024px){
    .hrrr{
        width: 72% !important;
    }
    .aout1-pa{
        padding-left: 5%;
        padding-right: 5%;
    }
}
@media (max-width:768px){
    .hrrr{
        width: 65% !important;
    }
    .contt{
        width: 100%;
    }
    .aout1-pa{
        padding-left: 5%;
        padding-right: 5%;
    }
}
@media (max-width:568px){
    .aout1-pa{
        padding-left: 3%;
        padding-right: 3%;
    }
    .pass{
        padding-top: 50px;
    }
    .tab1{
        margin-bottom: 130px;
    }
    .hrrr{
        display: none;
    }
    .mob-wish{
        margin-top: 40px;
    }
}
/* Media query for smaller screens */
@media (max-width: 1024px) {
    .table {
        width: 100vw;
        overflow-x: auto;
    }
}

.table tr {
    background-color: white;
    margin-bottom: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
}

.table img {
    border-radius: 5px;
}

.table td {
    vertical-align: middle;
}
