.interactive-con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 20px;
    padding-bottom: 30px;
}

.interactive-head {
    font-weight: 400;
    font-size: 35px;
}

.interactive-para {
    color: #777777;
}

@media (max-width: 768px) {
    .interactive-head {
        font-size: 20px;
    }
    .img1{
        display: none;
    }
}

.email-lead-con {
    background: linear-gradient(135deg, #e4510b 0, #e4510b 100%);
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.email-head {
    color: #efefef;
    font-size: 28px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
}

.email-para {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    color: #efefef;
    font-family: 'Lato', sans-serif;
}

.email-para1 {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: white;

    padding: 12px;

}



.final-con {
    background-color: #f3f3f3;
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.final-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.final-card-image {
    width: 40px;
    height: 40px;
}
.faqs{
    padding-bottom: 10% !important;
    background: #F8F7FA;
}
.head{
    font-size: 40px;
    font-weight: 400; 
}

@media (max-width: 568px) {
    .faqs{
        padding-bottom: 40% !important;
    }
    .head{
        font-size: 22px;
    }
}






