.container-custom {

    padding-right: 5%;
    padding-left: 5%;
}

.pad-right {

    padding-right: 5%;
}

.home-section-con {
    background-color: #F8F8F8;
    /* Vertically center content */
}

.home-sec-left {
    padding-left: 8%;
    background-image: url('../../assets/images/landing/Group\ 33812.png');
    width: 100%;
    background-size: cover;
}

.home-sec-left-title {
    font-size: 40px;
    color: #5C5C5C;
    line-height: 60px;
    font-weight: 600;
    margin-bottom: 35px;
}

.home-sec-left-title-bold {
    color: #ED0000;
}

.create-design-card {
    background-color: #EFEFF1;
    padding: 25px;
    border-radius: 16px;
    width: 100%;
    height: 100%;
}

.create-design-card-title {
    font-size: 24px;
    color: #878787;
}

.create-design-card-img {
    width: 230px;
    /* Adjust image size for better fit */
}

.create-design-card-btn {
    background-color: #E4510B;
    padding: 10px 20px;
    border: none;
    color: white;
    border-radius: 12px;
    font-size: 16px;
}

.create-design-card-ad {
    width: 100%;
    object-fit: contain;
    height: 100%;
    border-radius: 12px;
}

.create-design-card-slide {
    height: 200px;
    width: 300px;
    /* Ensures responsiveness */
    margin-right: 20px;
}

.marquee-container {
    height: 600px;
    width: 100%;
    padding: 10px;
}

.line-bar-new {
    width: 150px;
}

.left-arrow-cat {
    position: absolute;
    left: -24px;
    top: 32%;
}

.right-arrow-cat {
    position: absolute;
    right: -24px;
    top: 32%;
}

@media only screen and (max-width: 1390px) {
    .home-sec-left-title {
        font-size: 30px;
        color: #474747;
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .create-design-card-title {
        font-size: 18px;
    }

    .create-design-card-img {
        width: 190px;
    }

    .create-design-card-btn {
        padding: 8px 16px;
        font-size: 14px;
    }

    .line-bar-new {
        width: 150px;
    }

    .left-arrow-cat {
        position: absolute;
        left: -24px;
        top: 32%;
    }

    .right-arrow-cat {
        position: absolute;
        right: -24px;
        top: 32%;
    }
}


@media only screen and (max-width: 768px) {
    .home-sec-left-title {
        font-size: 30px;
        color: #474747;
        line-height: 40px;
        font-weight: 600;
        margin-bottom: 0px;
    }

    .home-sec-left {
        padding-left: 5%;
    }

    .create-design-card-img {
        width: 270px;
    }

    .create-design-card-title {
        font-size: 25px;
    }

    .create-design-card-btn {
        padding: 8px 16px;
        font-size: 18px;
    }

    .line-bar-new {
        width: 150px;
    }

    .left-arrow-cat {
        position: absolute;
        left: -24px;
        top: 32%;
    }

    .right-arrow-cat {
        position: absolute;
        right: -24px;
        top: 32%;
    }
}

@media only screen and (max-width: 600px) {
    .home-sec-left-title {
        font-size: 24px;
        margin-bottom: 0px;
    }

    .home-sec-left {
        padding-left: 5%;
    }

    .create-design-card-title {
        font-size: 18px;
    }

    .create-design-card-img {
        width: 150px;
    }

    .create-design-card-btn {
        padding: 8px 16px;
        font-size: 14px;
    }

    .cat-title {
        font-size: 20px;
    }

    .left-arrow-cat {
        position: absolute;
        left: -14px;
        top: 42%;
    }

    .right-arrow-cat {
        position: absolute;
        right: -14px;
        top: 42%;
    }
}

.react-multi-carousel-list {
    padding: 0px !important;
}

.product-card-new {
    width: 100%;
    border-radius: 16px;
    position: relative;
}

.product-card-new-btm {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding-top: 100px !important;
    background: linear-gradient(to bottom, transparent, black);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.color-bar {
    width: 30px;
    height: 8px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.6s ease-in-out;
}

.color-bar:hover {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition-duration: 3ms;
    border: 3px solid white;
}

.bg-add {
    background-color: #F8F8F8;
}

.explore-btn {
    background-color: transparent;
    border: 1px solid #E4510B;
    padding: 10px 20px;
    color: #E4510B;
    font-weight: 500;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.subscriber-letter-con {
    border-radius: 39px;
}

.subscriber-title {
    color: #414141;
    font-size: 45px
}

.subscriber-para {
    color: #2B2B2B;
    font-size: 20px;
    width: 60%;
}

.subscribe-input {
    width: 380px;
    height: 50px;
    border: 1px solid #C8C8C8;
    background-color: #EAEAEA;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    padding-left: 20px;
    font-size: 16px;
}

.subscribe-btn {
    background-color: #E4510B;
    padding: 10px 20px;
    border: none;
    color: white;
    border-radius: 25px;
    font-size: 16px;
    height: 50px;
}

@media only screen and (max-width: 1390px) {
    .subscribe-input {
        width: 300px;
        height: 45px;
        font-size: 16px;
    }

    .subscribe-btn {
        height: 45px;
        padding: 8px 16px;
        font-size: 16px;
    }

    .subscriber-title {
        color: #414141;
        font-size: 35px
    }

    .subscriber-para {
        color: #2B2B2B;
        font-size: 17px;
        width: 60%;
    }
}

@media only screen and (max-width: 600px) {
    .subscriber-title {
        color: #414141;
        font-size: 25px
    }

    .subscriber-para {
        width: 80%;
    }

    .subscribe-input {
        width: 180px;
        height: 40px;
        padding-left: 10px;
        font-size: 14px;
    }

    .subscribe-btn {
        height: 40px;
        padding: 7px 10px;
        font-size: 14px;
    }
}

.testimonial-con {
    border-radius: 20px;
}

.cat-and-prod-con {
    border-right: 1px solid #dadada;
    padding-right: 20px;
}

.cat-and-prod-para {
    color: #898989;
    font-size: 18px;
}

.product-list-con-new {
    height: 40px;
    width: 140px;
    border-radius: 36px;
    border: 2px solid #FFC3C3;
    padding: 2px 2px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.product-list-img {
    height: 100%;
    /* width: 38px; */
    border-radius: 50%;
}

.product-list-name {
    font-size: 12px;
    line-height: normal;
    color: #4A4A4A;
}

.para-car-card {
    color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px;
    width: 100%;
    font-size: 12px;
    background: linear-gradient(to bottom, transparent, black);
}

.para-car-card-con-active {
    border: 3px solid #E4510B;
}

.para-car-card-con {
    border-radius: 16px;
}

@media only screen and (max-width: 1000px) {
    .cat-and-prod-con {
        border-right: none;
        padding-right: 0px;
    }
}

@media only screen and (max-width: 1390px) {
    .para-car-card {
        padding: 8px;
        font-size: 10px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.testimonial-title {
    font-size: 30px;
    text-align: center;
    color: #414141;
}

.testimonial-para {
    text-align: center;
    color: #898989;
    width: 70%;
}

.testimonial-con {
    padding-left: 50px;
    padding-right: 50px;
}

.testimonial-profile {
    border-radius: 50%;
    height: 160px;
    width: 160px;
}

.testimonial-card {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.testimonial-review-con {
    padding-left: 40px;
    padding-right: 40px;
}

@media only screen and (max-width: 600px) {
    .testimonial-profile {
        border-radius: 50%;
        height: 120px;
        width: 120px;
    }

    .testimonial-con {
        padding-left: 0px;
        padding-right: 0px;
    }

    .testimonial-para {
        width: 80%;
    }

    .testimonial-review-con {
        padding-left: 8px;
        padding-right: 8px;
    }
}