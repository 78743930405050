.shopnow {
    text-decoration: underline;
}

.product-card-con {
    /* display: flex; */
    flex-direction: row;
    flex-wrap: wrap;
}

.product-card {
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 310px !important;
    margin: 2px;
    border: 2px solid rgb(255, 255, 255) !important;
}

.product-card:hover {

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: 2px solid black !important;
}

.accordion-header.active {
    background-color: initial !important;
    border-color: initial !important;
}

.product-card-description {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    line-height: 23px;
    color: rgb(90, 90, 90);
    margin: 0px;
    padding: 0px;
}

.product-card-title {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: rgb(90, 90, 90);
    font-weight: 600;
}



@media (max-width: 568px) {
    .product-card-con {
        justify-content: center;
        padding-bottom: 20px;
    }

    .call2 {
        display: none;
    }

    .animate-width {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .product-card-con {
        justify-content: center;
        padding-bottom: 30px;
    }

    .call2 {
        display: none;
    }

    .animate-width {
        width: 100%;
    }
}

@media (max-width: 1490px) {
    .product-card-con {
        justify-content: center;
    }

    .animate-width {
        width: 50%;
    }
}


@media (max-width: 1280px) {
    .call2 {
        width: 68% !important;
    }

    .animate-width {
        width: 50%;
    }
}

.product-overall-title {
    font-size: 32px;
    font-weight: 600;
    min-width: fit-content !important;
    color: #2B2B2D;
    margin-bottom: 7px;
    line-height: 35px;
}

.time {
    width: 100px;
}

.product-overall-title1 {
    font-size: 32px;
    font-weight: 600;
    min-width: fit-content !important;
    color: #2B2B2D;
    margin-bottom: 7px;
    line-height: 35px;
}



.animate-video-width {
    width: 700px;
}

@media (max-width: 568px) {
    .product-overall-title {
        font-size: 22px;
        text-align: center;
    }

    .animate-video-width {
        width: 340px;
    }

    .product-overall-title1 {
        margin-top: 100px;
    }

    .fast-card {
        margin-bottom: 70px;
    }
}