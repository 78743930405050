.sizeChartImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.size-container{
    position: relative;
    width: 400px;
    height: auto;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image-remove{
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    color: red;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}
.upload-image{
    border: 3px dashed #e4510b;
    border-radius: 15px;
    padding: 40px;
}
.displayImage{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}
.upload{
    width: 100%;
    height: 30px;
    background:#E4510B ;
    border-radius: 5px;
}
.tshirt-custompicture1{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
}
.spinner{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textSpan{
    font-family: Lato, sans-serif;
    font-weight: bold;
    color: white;
}
.unit-btn{
    width: 50px;
    height: 40px;
    border: none;
    color: black;
    background-color: blue;
}
.unit-btn1{
    width: 50px;
    height: 40px;
    border: none;
    color: black;
    background-color: rgb(157, 157, 243);
}
.table-head{
    width:150px;
    font-size: 18px;
    font-weight: 600;
    background: #E4510B;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
}
.table-button{
    width:50px;
    font-size: 18px;
    font-weight: 600;
    background: #E4510B;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
}
