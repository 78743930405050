.log{
        color: black;
        font-size: 18px;
        display: flex;
        text-decoration: none;
        justify-content: center;
        align-items: center;
}
.pincode-dropdown {
  max-height: 200px; /* Limit the height to fit 4 items */
  overflow-y: auto;  /* Make the dropdown scrollable */
  border: 1px solid #ccc;
  background-color: #fff;
  position: absolute;
  width: 100%;
  z-index: 1000; /* Ensure it stays on top of other elements */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
      
.pincode-suggestion {
  padding: 8px;
  cursor: pointer;
}
      
.pincode-suggestion:hover {
  background-color: #f0f0f0;
}
      
@media (max-width: 568px) {
  .profile{
    padding-bottom: 100px;
  }
}
