.customization-con {
    position: relative;
    min-height: 100vh;
}

@media (max-width: 1008px) {
    .customization-con {
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

/* Mobile Customization */

.mobile-con-cust {
    min-height: 100vh;
}