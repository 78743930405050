.guarante-para {
    line-height: 1.7em;
    font-size: 18px;
}

.guarante-images {
    height: 100px;
    width: 100px;
}

.guarante-left {
    width: 100%;
    height: 100%;
}